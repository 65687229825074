import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box, Stack } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { flexPositions } from '../utils'
import { P } from '../globals'

function Nav({ children, ...restProps }) {
  return (
    <Stack
      as="nav"
      isInline
      spacing={4}
      alignItems="center"
      overflowX="auto"
      height="100%"
      flex={{ base: '0 0 100%', md: 'auto' }}
      {...restProps}
    >
      {children}
    </Stack>
  )
}

export const MenuLink = styled(P)`
  opacity: 0.85;
  padding: 14px 0;
  display: block;
  cursor: pointer;
  font-weight: normal;
  letter-spacing: 0.85px;
  word-wrap: nowrap;
  white-space: nowrap;
  font-weight: bold;

  &:hover {
    opacity: 1;
  }
`

function Menu({ children, logo, rightButton, navPosition, ...restProps }) {
  return (
    <Flex
      justify="space-between"
      width="100%"
      minHeight={{ base: 'auto', lg: '65px' }}
      paddingX={{ base: 6, md: 10, xl: 20 }}
      paddingTop={{ base: 6, xl: 2 }}
      paddingBottom={{ xl: 2 }}
      alignItems="center"
      flexWrap="wrap"
      {...restProps}
    >
      {logo}
      {rightButton && (
        <Box marginLeft={4} order={{ base: 0, xl: 1 }}>
          {rightButton}
        </Box>
      )}
      <Nav
        order={{ base: 1, md: 0 }}
        justify={{ base: 'flex-start', xl: flexPositions[navPosition] }}
        flex={{ base: '0 0 100%', xl: '1' }}
      >
        {children}
      </Nav>
    </Flex>
  )
}

Menu.propTypes = {
  navPosition: PropTypes.oneOf(['left', 'center', 'right']),
  logo: PropTypes.node,
  children: PropTypes.node,
  rightButton: PropTypes.node,
}

Menu.defaultProps = {
  logo: '',
  children: '',
  rightButton: null,
  navPosition: 'right',
}

export default Menu
