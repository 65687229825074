import React from 'react'
import { useTranslation, Link } from 'gatsby-plugin-react-i18next'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { Flex } from '@chakra-ui/react'

import { Menu, MenuLink } from '../ui'

import ButtonBook from './buttonBook'

import logo from '../images/logo-white.png'

function Header({ variantHeader, ...restProps }) {
  const { t } = useTranslation()

  return (
    <Menu
      logo={
        <Link to="/">
          <Flex height={{ base: '35px', md: '50px' }} justifyContent={{ lg: 'center' }}>
            <img style={{ height: '100%', width: 'auto' }} src={logo} />
          </Flex>
        </Link>
      }
      zIndex={2}
      rightButton={<ButtonBook />}
      position={{
        md: variantHeader === 'solid' ? 'initial' : 'absolute',
      }}
      {...restProps}
    >
      <MenuLink color="white" as={Link} to="/" fontWeight="bold">
        Home
      </MenuLink>
      <MenuLink color="white" as={Link} to="/rooms" fontWeight="bold">
        Habitaciones
      </MenuLink>
      <MenuLink color="white" as={Link} to="/weddings-and-retreats" fontWeight="bold">
        Bodas y Retiros
      </MenuLink>
      <MenuLink color="white" as={Link} to="/contact" fontWeight="bold">
        Contacto
      </MenuLink>
    </Menu>
  )
}

Header.propTypes = {
  variantHeader: PropTypes.oneOf(['solid', 'transparent']),
}

Header.defaultProps = {
  variantHeader: 'solid',
}

export default Header
