import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Button } from '@chakra-ui/react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { ImWhatsapp } from 'react-icons/im'

import { useSetRecoilState } from 'recoil'
import { bookingModalState } from '../store'
import { P } from '../ui'

function ButtonBook({ children, arrival, departure, promotion, ...restProps }) {
  const { t } = useTranslation()
  const setBookingModal = useSetRecoilState(bookingModalState)

  useEffect(() => {
    setBookingModal((obj) => ({
      ...obj,
      arrival,
      departure,
      promotion: promotion && `promotion/${promotion}/`,
    }))
  }, [])

  return (
    <Button
      as="a"
      href={`https://api.whatsapp.com/send?phone=5219984205276&text=Hola Alan, quiero más información sobre los terrenos en Hakuna Matata...`}
      width="fit-content"
      background="green"
      _hover={{ background: 'green' }}
      color="white"
      height="80px"
      // onClick={() => setBookingModal((obj) => ({ ...obj, isOpen: true }))}
      {...restProps}
    >
      <ImWhatsapp color="white" size="1.75rem" />
      <Box marginLeft={3}>
        <P color="white" fontSize="lg" fontWeight="bold" opacity={1}>
          Quiero más información
        </P>
        <P color="white" fontSize="sm" textAlign="center" marginTop={1} opacity={1}>
          3 personas han pedido información
        </P>
      </Box>
    </Button>
  )
}

ButtonBook.propTypes = {
  children: PropTypes.node,
  colorScheme: PropTypes.string,
  arrival: PropTypes.string,
  departure: PropTypes.string,
  promotion: PropTypes.string,
}

ButtonBook.defaultProps = {
  children: null,
  arrival: '',
  departure: '',
  promotion: '',
}

export default ButtonBook
