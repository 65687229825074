import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Global, css } from '@emotion/react'
import { Link, useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import { useRecoilState } from 'recoil'
import { MdLanguage } from 'react-icons/md'

import { Flex, Button } from '@chakra-ui/react'

import {
  Footer,
  FooterBody,
  FooterColumn,
  FooterDivider,
  FooterBottom,
  Main,
  P,
  BookingModal,
  WhatsappButton,
  Popup,
} from '../ui'

import SocialLinks from './socialLinks'
import Header from './header'
import FloatingBook from './floatingBook'

import { bookingModalState } from '../store'
import SEO from './seo'
import ButtonBook from './buttonBook'

import logoFooter from '../images/logo-footer.png'

const globalStyles = css`
  @font-face {
    font-family: Open Sans;
    font-style: normal;
    font-display: swap;
    font-weight: 400;
  }

  @font-face {
    font-family: Open Sans;
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  * {
    font-size: 14px;
    font-family: Open Sans, Arial, Helvetica, sans-serif !important;
    font-weight: 400;

    @media (min-width: 1024px) {
      font-size: 16px;
    }
  }

  p,
  span {
    font-size: 16px;
    max-height: 999999px;
    opacity: 0.85;
  }
`

function Layout({
  children,
  title,
  variantHeader,
  disableHeader,
  disableWhatsapp,
  disablePopup,
  disableFloatingBanner,
}) {
  const isCoyote = process.env.GATSBY_HOTEL_VAR === 'casa-coyote'

  const { t } = useTranslation()
  const { language, changeLanguage } = useI18next()
  const [bookingModal, setBookingModal] = useRecoilState(bookingModalState)

  return (
    <>
      <Global styles={globalStyles} />
      <SEO title={`${title}`} />

      {/* {!disableHeader && (
        <Header
          variantHeader={variantHeader}
          backgroundColor={{
            base: '#693036',
            md: variantHeader === 'solid' ? '#693036' : 'rgba(0,0,0,0.45)',
          }}
        />
      )} */}
      <Flex direction="column" minHeight="100vh">
        <Main>{children}</Main>
      </Flex>
      {/* <Footer backgroundColor="gray.200">
        <FooterBody>
          <FooterColumn align="center">
            <Link to="/">
              <Flex width={{ base: '250px', md: '300px' }}>
                <img src={logoFooter} />
              </Flex>
            </Link>
            <P>
              El pasado entra en las venas a través de los recuerdos y de una experiencia sensible
              con la naturaleza.
            </P>
            <Flex>
              <Button
                colorScheme="black"
                variant="outline"
                leftIcon={<MdLanguage />}
                onClick={() => changeLanguage(language === 'es' ? 'en' : 'es')}
                _hover={{ background: 'transparent' }}
              >
                {language === 'es' ? 'Change to English' : 'Cambiar a Español'}
              </Button>
            </Flex>
          </FooterColumn>

          <FooterColumn />

          <FooterColumn title="CONTÁCTANOS">
            <SocialLinks />
          </FooterColumn>
        </FooterBody>
        <FooterDivider backgroundColor="red.800" />
        <FooterBottom direction={{ base: 'column', md: 'row' }} justify="space-between">
          <P fontSize="sm" lineHeight="shorter" textAlign={{ base: 'center', lg: 'left' }}>
            © Hotel Hacienda Poxilá. Todos los derechos reservados.
          </P>
          <P fontSize="sm" lineHeight="shorter" textAlign={{ base: 'center', lg: 'left' }}>
            Developed by{' '}
            <P
              fontSize="sm"
              as="a"
              href="https://www.hmnagency.com"
              textDecoration="underline"
              target="_blank"
            >
              hmnagency.com
            </P>
          </P>
        </FooterBottom>
      </Footer> */}

      {!disableWhatsapp && (
        <WhatsappButton
          bottom="75px"
          href={`https://api.whatsapp.com/send?phone=5219983497125&text=Hola Alan, quiero más información sobre los terrenos en Hakuna Matata...`}
        />
      )}

      {/* {!disableFloatingBanner && (
        <FloatingBook
          title="Hacienda Poxilá"
          desc="La mejor tarifa web, garantizado"
          colorScheme="red"
        />
      )} */}

      {/* {!disablePopup && (
        <Popup>
          <ButtonBook
            width="100%"
            height="100%"
            promotion="lastminute"
            arrival="2021-02-15"
            departure="2021-02-16"
          >
            <img
              style={{ width: '100%' }}
              src={
                language === 'es'
                  ? isCoyote
                    ? popupEsCoyote
                    : popupEsXaman
                  : isCoyote
                  ? popupEnCoyote
                  : popupEnXaman
              }
            />
          </ButtonBook>
        </Popup>
      )} */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  disableHeader: PropTypes.bool,
  disableWhatsapp: PropTypes.bool,
  disablePopup: PropTypes.bool,
  disableFloatingBanner: PropTypes.bool,
  title: PropTypes.string,
  variantHeader: PropTypes.oneOf(['solid', 'transparent']),
}

Layout.defaultProps = {
  disableHeader: false,
  disableWhatsapp: false,
  disablePopup: false,
  disableFloatingBanner: false,
  title: '',
  variantHeader: 'transparent',
}

export const query = graphql`
  fragment fixedImage on File {
    childImageSharp {
      fixed(width: 680, quality: 65) {
        ...GatsbyImageSharpFixed
      }
    }
  }

  fragment bannerSectionImage on File {
    childImageSharp {
      fluid(maxWidth: 1270, quality: 65) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  fragment multipleFixedImage on FileConnection {
    edges {
      node {
        childImageSharp {
          fixed(width: 860, quality: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`

export default Layout
