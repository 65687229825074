import React from 'react'
import styled from '@emotion/styled'
import { FaMapMarkedAlt } from 'react-icons/fa'
import { Stack, IconButton } from '@chakra-ui/react'

import { P } from '../ui'

const A = styled.a`
  font-size: inherit;
  text-decoration: underline;
`

const SocialLink = ({ icon, to }) => (
  <IconButton as="a" href={to} target="_blank" variant="outline" colorScheme="white" icon={icon} />
)

function SocialLinks(props) {
  return (
    <Stack spacing={2} {...props}>
      <P>
        Email: <A href="mailto:info@hotelhaciendapoxila.com">info@hotelhaciendapoxila.com</A>
      </P>
      <P>Tel: +52 1 999 999 9999</P>
      <P>Poxilá, Umán, Yucatán</P>
      <Stack isInline shouldWrapChildren>
        {/* <SocialLink to="https://www.instagram.com/casa_coyote/" icon={<FaInstagram />} /> */}
        <SocialLink
          to="https://www.google.com/maps/place/hacienda+poxila/@20.8147417,-89.8053381,15z/data=!4m2!3m1!1s0x0:0x19c69c95e76925eb?sa=X&hl=en&ved=2ahUKEwiCt_b6ipHxAhU0VTABHWxzCSUQ_BIwC3oECDAQBQ"
          icon={<FaMapMarkedAlt />}
        />
      </Stack>
    </Stack>
  )
}

export default SocialLinks
